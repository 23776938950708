import JSBI from 'jsbi'

// exports for external consumption
export type BigintIsh = JSBI | bigint | string

export enum ChainId {
  BLAST_SEPOLIA = 168587773,
  BLAST_MAINNET = 97
}

export enum TradeType {
  EXACT_INPUT,
  EXACT_OUTPUT
}

export enum Rounding {
  ROUND_DOWN,
  ROUND_HALF_UP,
  ROUND_UP
}

export const FACTORY_ADDRESS = {
  [ChainId.BLAST_SEPOLIA]: '0x0A98e24c6bc15DE8493AA660f788Bb260fb4d1F9',
  [ChainId.BLAST_MAINNET]: '0x56D81F99234287b407c48564435Abc85f34E1501'
}

export const INIT_CODE_HASH = {
  [ChainId.BLAST_SEPOLIA]: '0x8db2930c7d4d68fa7ed86374cceb66a269cf8d0659361d291c136a6b2741321b',
  [ChainId.BLAST_MAINNET]: '0xac1ca2433fa84513480cb47346ff89c9296353072eb2af7418c6dda2a0af959b'
}

export const MINIMUM_LIQUIDITY = JSBI.BigInt(1000)

// exports for internal consumption
export const ZERO = JSBI.BigInt(0)
export const ONE = JSBI.BigInt(1)
export const TWO = JSBI.BigInt(2)
export const THREE = JSBI.BigInt(3)
export const FIVE = JSBI.BigInt(5)
export const TEN = JSBI.BigInt(10)
export const _100 = JSBI.BigInt(100)
export const _997 = JSBI.BigInt(997)
export const _1000 = JSBI.BigInt(1000)

export enum SolidityType {
  uint8 = 'uint8',
  uint256 = 'uint256'
}

export const SOLIDITY_TYPE_MAXIMA = {
  [SolidityType.uint8]: JSBI.BigInt('0xff'),
  [SolidityType.uint256]: JSBI.BigInt('0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff')
}
